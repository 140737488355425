import React from 'react';
import './App.css';
import { ReactComponent as UploadIcon } from './upload-icon.svg';

function App() {
  const fileInputRef = React.useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file);
      // Handle the file upload here
    }
  };

  return (
    <div className="App">
      <div className="container">
        <div className="top">
          <div className="top-left" onClick={handleIconClick}>
            <UploadIcon className="upload-icon" />
            <p>Upload 3D file</p>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
          <div className="top-right">
            <h2>Logs</h2>
          </div>
        </div>
        <div className="bottom">
          <div className="chat-container">
            <div className="chat-history">
              {/* Chat history will go here */}
            </div>
            <div className="chat-input">
              <input type="text" placeholder="Enter your message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
